import React, {Fragment} from "react"

import '../../scss/selectable-list.scss'

import Selectable from '../selectable'



class ClosedSelectableList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {clicked: 0};
    }

    click(id, text){
      this.setState({
          clicked: id,
      })
      this.props.onClick(text)
  }

    render(){
    return(
    <div className="selectable--list--container">
      {
      this.props.selectablesList.map((text, i) => {
        return(
          <div onClick={() => this.click(i, text)}className={(this.state.clicked === i) ? "selectable--container active" : "selectable--container"}>
            <p className={(this.state.clicked === i) ? "selectable--text active" : "selectable--text"}>{text}</p>
          </div>
        )
      }, this) 
      }
     
    </div>
    );
    }
}

export default ClosedSelectableList;
